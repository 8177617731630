<template>
  <el-row class="table">
    <span class="title">课程管理</span>
    <el-row class="row-bg">
      <el-input
        class="tb-sm-input"
        v-model.trim="queryData.inputName"
        placeholder="请输入课程名称"
        @keyup.enter.native="search"
      >
      </el-input>
      <el-input
        class="tb-sm-input"
        v-model.trim="queryData.inputCreator"
        placeholder="请输入创建人"
        @keyup.enter.native="search"
      >
      </el-input>
      <el-select
        v-model="queryData.seleState"
        placeholder="选择状态"
        class="tb-smselect"
        @change="handleSeleState"
      >
        <el-option
          v-for="item in queState"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button
        type="primary"
        size="medium"
        @click="search()"
        class="tb-button"
        >查 询</el-button
      >
      <el-button type="primary" size="medium" @click="reset()" class="tb-button"
        >重 置</el-button
      >
      <el-button
        type="info"
        size="medium"
        @click="addCurriculum()"
        class="tb-button"
        v-has="'curriculum:ManageAdd'"
        >新 增</el-button
      >
    </el-row>
    <el-table
      :data="dataList"
      class="tb-list"
      style="width: 100%; margin-bottom: 20px"
      border
    >
      <el-table-column label="课程名称" align="left">
        <template slot-scope="scope">
          <span class="title-link" @click="skipDetails(scope.row)">{{
            scope.row.title
          }}</span>
        </template>
      </el-table-column>
      <!--      <el-table-column label="课程类别" align="center">-->
      <!--        <template slot-scope="scope">-->
      <!--          <span>{{scope.row.catalogTitle}}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="课程状态" align="center">
        <template slot-scope="scope">
          <span
            :style="'color' + ':' + getStateLessonColor(scope.row.status)"
            >{{ scope.row.status }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="课程类型" align="center" prop="type">
      </el-table-column>

      <el-table-column label="所属分组" align="center" prop="courseGroup">
      </el-table-column>

      <el-table-column label="所属分类" align="center" prop="catalogTitle">
      </el-table-column>
      <el-table-column label="创建人" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.creatorName }}</span>
        </template>
      </el-table-column>


      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | dateFormat }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button type="primary" size="small">
              管 理<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="skipDetails(scope.row)">
                <span class="title-link">管理课程</span>
              </el-dropdown-item>
              <el-dropdown-item
                @click.native="publishCourse(scope.row)"
                v-if="scope.row.status === '已关闭' || scope.row.status === '未发布'"
                ><span>发布课程</span></el-dropdown-item>
              <el-dropdown-item
                @click.native="closeCurriculum(scope.row)"
                v-else
                v-has="'curriculum:ManageClose'"
                ><span>关闭课程</span></el-dropdown-item
              >
              <el-dropdown-item
                @click.native="delCurriculum(scope.row)"
                v-has="'curriculum:ManageDel'"
                ><span>删除课程</span></el-dropdown-item
              >
              <el-dropdown-item
                @click.native="archiveCurriculum(scope.row)"
                v-has="'curriculum:ManageReturn'"
                ><span>课程归档</span></el-dropdown-item
              >
              <!--              <el-dropdown-item @click.native="copyCurriculum(scope.row)"  v-has="'curriculum:courseCopy'"><span>课程复制</span></el-dropdown-item>-->
              <!-- <el-dropdown-item @click.native="copyCurriculum(scope.row)"><span>课程复制</span></el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
    <!-- 新增课程 dialog -->
    <add-curriculum
      :show="curriculumDialog"
      @close="curriculumDialog = false;editData = null;"
      :editData="editData"
      @updateView="updateView"
    ></add-curriculum>
    <publish-tips
      :show="publishDialog"
      @close="publishDialog = false"
      :editData="editData"
      @publishCourse="publishCurriculum"
    ></publish-tips>
  </el-row>
</template>

<script>
import addCurriculum from "@/components/research/addCurriculum";
import publishTips from "@/components/research/publishTips";
import getStateLessonColor from "@/mixins/getStateColor";
import {
  getCourseList,
  editCourse,
  delCourse,
} from "@/api/research/curriculum";

export default {
  components: {
    addCurriculum,
    publishTips
  },
  mixins: [getStateLessonColor],
  data() {
    return {
      publishDialog:false,
      queryData: {
        inputName: "",
        inputCreator: "",
        seleState: "",
      },
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      curriculumDialog: false,
      editData: null,
      queState: [
        {
          label: "未发布",
          value: "未发布",
        },
        {
          label: "已发布",
          value: "已发布",
        },
        {
          label: "已关闭",
          value: "已关闭",
        },
      ],
    };
  },
  created() {
    this.getCourseList();
  },
  methods: {
    // 获取课程列表
    async getCourseList() {
      const res = await getCourseList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        status: this.queryData.seleState,
        title: this.queryData.inputName,
        creatorName: this.queryData.inputCreator,
      });
      this.dataList = res.body.list;
      this.pagination.total = res.body.total;
    },

    // 添加课程
    addCurriculum() {
      this.curriculumDialog = true;
    },

    // 编辑
    editCurriculum(row) {
      this.editData = row;
      this.curriculumDialog = true;
    },

    // 发布课程二次提示
    publishCourse(row) {
      this.editData = row;
      this.publishDialog = true;
    },

    // 状态
    handleSeleState(val) {
      this.seleState = val;
    },

    // 重置
    reset() {
      this.queryData = {};
    },

    // 发布课程
    publishCurriculum(row) {
      editCourse({
        id: row.id,
        status: "已发布",
      }).then((res) => {
        if (res.state === "success") {
          window.$msg("发布成功");
          this.getCourseList();
        }
      });
    },

    // 关闭课程
    closeCurriculum(row) {
      this.$confirm("确定关闭当前课程吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          editCourse({
            id: row.id,
            status: "已关闭",
          }).then((res) => {
            if (res.state === "success") {
              window.$msg("关闭成功");
              this.getCourseList();
            }
          });
        })
        .catch(() => {
          return false;
        });
    },

    // 课程归档
    archiveCurriculum(row) {
      editCourse({
        id: row.id,
        status: "已归档",
      }).then((res) => {
        if (res.state === "success") {
          window.$msg("归档成功");
          this.getCourseList();
        }
      });
    },

    // 课程复制
    // copyCurriculum (row) {
    //   cloneCourse({
    //     courseId: row.id
    //   }).then(res => {
    //     if (res.state === 'success') {
    //       window.$msg('复制成功')
    //       this.getCourseList()
    //     }
    //   })
    // },

    // 更新视图
    updateView() {
      this.getCourseList();
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getCourseList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getCourseList();
    },

    // 搜索
    search() {
      this.pagination.currentPage = 1;
      this.getCourseList();
    },

    // 删除
    delCurriculum(row) {
      this.$confirm("确定删除当前课程吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCourse({
            ids: [row.id],
          }).then((res) => {
            if (res.state === "success") {
              window.$msg("删除成功");
              this.getCourseList();
            }
          });
        })
        .catch(() => {
          return false;
        });
    },

    // 跳转课程详情
    skipDetails(row) {
      this.$router.push({
        path: "/research/curriculum-details",
        query: {
          curriculumID: row.id,
          courseId: row.id,
          courseName: row.title,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../style/table.scss";
</style>
