var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", [_vm._v("发布课程")]),
          _c(
            "span",
            { staticStyle: { padding: "15px" } },
            [
              _c(
                "el-tag",
                {
                  staticClass: "el-icon-warning",
                  staticStyle: { "font-size": "16px", color: "#E65962" },
                },
                [
                  _vm._v(
                    "课程发布后，以下课程信息将无法变更，请再次确认以下信息是否正确"
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: { model: _vm.formData, "label-width": "120px" },
        },
        [
          _c("el-form-item", { attrs: { label: "课程类型：" } }, [
            _c("span", [_vm._v(_vm._s(_vm.formData.type))]),
          ]),
          _c("el-form-item", { attrs: { label: "账号类型：" } }, [
            _c("span", [_vm._v(_vm._s(_vm.formData.courseGoods))]),
          ]),
          _c("el-form-item", { attrs: { label: "课节详情：" } }, [
            _c("span", { staticStyle: { color: "#E65962" } }, [
              _vm._v("(发布课程后，课节数、课节顺序无法变更)"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: {
            "margin-top": "15px",
            "margin-bottom": "30px",
            width: "50%",
            "margin-left": "30px",
          },
          attrs: { data: _vm.formData.dataList, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "课次", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v("第" + _vm._s(scope.row.sort) + "课")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "title", label: "课节标题", align: "center" },
          }),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveCategory },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }