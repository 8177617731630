<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="curriculumTitle"></span>
    </div>
    <el-form
      ref="formData"
      :model="formData"
      :rules="rules"
      label-width="150px"
    >
      <el-form-item label="所属分组：" prop="courseGroup">
        <el-select
          v-model="formData.courseGroup"
          placeholder="请选择所属分组"
          style="width: 100%"
          @change='change'
        >
          <el-option
            v-for="item in courseGroupList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属分类：" prop="catalogId">
        <el-select
          v-model="formData.catalogId"
          placeholder="请选择所属分类"
          style="width: 100%"
        >
          <el-option
            v-for="item in catalogIdList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程名称：" prop="title">
        <el-input
          v-model="formData.title"
          placeholder="请输入课程名称（不可重名）"
          maxlength="16"
        ></el-input>
      </el-form-item>
      <!--      <el-form-item label="课程类别：" prop="catalogId">-->
      <!--        &lt;!&ndash; 级联选择器 &ndash;&gt;-->
      <!--        <el-cascader-->
      <!--          v-model="formData.catalogId"-->
      <!--          :options="cascaderList"-->
      <!--          :props="{ checkStrictly: true, value: 'id', label: 'title' }">-->
      <!--        </el-cascader>-->
      <!--      </el-form-item>-->
      <el-form-item label="课程昵称：">
        <el-input
          v-model="formData.nick"
          placeholder="请输入课程昵称（展示给购课学员）"
          maxlength="64"
        ></el-input>
      </el-form-item>
      <el-form-item label="课程类型：" prop="type">
        <!-- <el-switch
          v-model="formData.defOpen"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="试听"
          inactive-text="常规">
        </el-switch> -->
        <el-select
          v-model="formData.type"
          placeholder="请选择课程类型"
          style="width: 100%"
        >
          <el-option
            v-for="item in (formData.courseGroup == '培训' ? typeLists :typeList)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="课程账号类型："  prop="series" v-if="formData.courseGroup === '编程'">
        <el-select
          v-model="formData.series"
          placeholder="请选择课程账号类型"
          style="width: 100%"
        >
          <el-option
            v-for="item in cascaderList"
            :key="item.id"
            :label="item.courseGoodsName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!--      <el-form-item label="知识点关联：">-->
      <!--        <el-select v-model="formData.knowledge"  collapse-tags multiple  placeholder="知识点关联">-->
      <!--          <el-option-->
      <!--            v-for="item in knowledgeList"-->
      <!--            :key="item.knowledgeId"-->
      <!--            :label="item.content"-->
      <!--            :value="item.knowledgeId">-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
    </el-form>
    <!-- footer -->
    <el-row slot="footer">
      <el-button type="primary" @click="saveCategory()" class="dialog-btn"
        >确 定</el-button
      >
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute editData 根据有无 editData数据 动态渲染 dialog弹出框 数据
 * @function close 自定义弹窗关闭事件
 */
import {
  addCourse,
  editCourse,
  courseCatalog,
  $getCatalogTree
} from "@/api/research/curriculum";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    editData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },

  // 监控表单变化，清除验证
  watch: {
    show() {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields();
        }
      }
    },
  },

  data() {
    return {
      cascaderList: [],
      curriculumTitle: "",
      formData: {},
      rules: {
        title: [
          { required: true, message: "课程名称不能为空", trigger: "blur" },
          { max: 50, message: "字数限制在50个字内", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择课程类型", trigger: "blur" }],
        series: [
          { required: true, message: "请选择课程账号类型", trigger: "blur" },
        ],
        courseGroup: [
          { required: true, message: "请选择所属分组", trigger: "blur" },
        ],
        catalogId: [
          { required: true, message: "请选择所属分类", trigger: "blur" },
        ],
      },
      categoryList: [],
      typeList: [
        {
          value: "常规",
          label: "常规",
        },
        {
          value: "双师",
          label: "双师",
        },
        {
          value: "试听",
          label: "试听",
        },
        {
          value: "视频",
          label: "视频",
        }
      ],
      typeLists: [
        {
          value: "培训",
          label: "培训",
        },
      ],
      courseGroupList: [
        {
          value: "编程",
          label: "编程",
        },
        {
          value: "培训",
          label: "培训",
        },
      ],
      catalogIdList:[]
    };
  },
  created() {
    this.courseCatalog();
  },
  mounted(){

  },
  methods: {
    change(){
      this.getCatalogTree(this.formData.courseGroup)

    },
    async getCatalogTree() {
      const res = await $getCatalogTree({
        group:this.formData.courseGroup
      });
      this.catalogIdList = res.body;
      console.log(res,'res')
    },
    async courseCatalog() {
      const res = await courseCatalog({
        pageNum: 1,
        pageSize: 1000,
        needCount: true,
      });
      this.cascaderList = res.body.list;
    },
    openDialog() {
      this.formData = {};
      if (this.editData !== null) {
        this.curriculumTitle = "编辑课程";
        this.formData = { ...this.editData };
      } else {
        this.curriculumTitle = "新增课程";
      }
    },

    // 关闭弹窗
    close() {
      this.$emit("close");
    },

    // 保存
    saveCategory() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (this.editData !== null) {
            editCourse({
              id: this.formData.id,

              // catalogId: typeof (this.formData.catalogId) === 'string' ? this.formData.catalogId : this.formData.catalogId[this.formData.catalogId.length - 1],
              title: this.formData.title,
              nick: this.formData.nick,
              // type: this.formData.defOpen ? "试听":"常规"
              type: this.formData.type,
            }).then((res) => {
              if (res.state === "success") {
                window.$msg("编辑成功");
                this.close();
                this.$emit("updateView");
              }
            });
          } else {
            addCourse({
              // catalogId: this.formData.catalogId[this.formData.catalogId.length - 1],
              title: this.formData.title,
              nick: this.formData.nick,
              type: this.formData.type,
              courseGoodsId: this.formData.series,
              catalogId:this.formData.catalogId,
              courseGroup:this.formData.courseGroup,
            }).then((res) => {
              if (res.state === "success") {
                window.$msg("添加成功");
                this.close();
                this.$emit("updateView");
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
