<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>发布课程</span>
      <span style="padding: 15px;">
      <el-tag  class="el-icon-warning"  style="font-size: 16px;color: #E65962">课程发布后，以下课程信息将无法变更，请再次确认以下信息是否正确</el-tag>
    </span>
    </div>

    <el-form
      ref="formData"
      :model="formData"
      label-width="120px"
    >
      <el-form-item label="课程类型：">
        <span>{{formData.type}}</span>
      </el-form-item>
      <el-form-item label="账号类型：">
        <span>{{formData.courseGoods}}</span>
      </el-form-item>
      <el-form-item label="课节详情：">
        <span style="color: #E65962">(发布课程后，课节数、课节顺序无法变更)</span>
      </el-form-item>
    </el-form>
    <el-table
      :data="formData.dataList"
      border
      style="margin-top: 15px;margin-bottom: 30px;width: 50%;margin-left: 30px;"
    >
      <el-table-column
        label="课次"
        align="center">
        <template slot-scope="scope">
          <span>第{{scope.row.sort}}课</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="课节标题"
        align="center">
      </el-table-column>
    </el-table>
    <el-row slot="footer">
      <el-button type="primary" @click="saveCategory" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute editData 根据有无 editData数据 动态渲染 dialog弹出框 数据
 * @function close 自定义弹窗关闭事件
 */

import {getCourseUnitListOnly, getInfo} from '@/api/senate/class'
import { getCourseInfo} from '@/api/research/curriculum'
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    editData: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },

  data() {
    return {
      formData: {
        courseGoods:'',
        type:'',
        amount:0,
        dataList:[]
      },
    };
  },

  methods: {
    openDialog() {
      this.formData =  {
        courseGoods:'',
        type:'',
        amount:'',
        dataList:[]
      };
      this.formData.amount = 0
      this.formData.type = this.editData.type
      this.getCourseUnitListOnly();
      this.getCourseInfo()
    },
    async getCourseInfo () {
      const res = await getCourseInfo({
        id: this.editData.id
      })
      if(res.body.courseGoods) {
        this.formData.courseGoods = res.body.courseGoods.courseGoodsName
      }
    },
    async getCourseUnitListOnly() {
      const res = await getCourseUnitListOnly({
        pageNum: 1,
        pageSize: 1000,
        courseId: this.editData.id
      })
      this.formData.dataList = res.body.list
      this.formData.dataList.forEach(item => {
        this.formData.amount = this.formData.amount + item.comsumeAmount
      })
    },

    saveCategory() {
      this.$emit("publishCourse",this.editData);
      this.$emit("close");
    },
    // 关闭弹窗
    close() {
      this.$emit("close");
    },

  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 0px !important;
  padding-top:30px !important;
}
@import "../../../style/dialog.scss";
</style>
